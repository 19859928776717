import axios from 'axios';

const fetchData = async (selectedItem) => {
  try {
    let url = '';
    switch (selectedItem) {
      case 'axonUsersNotInWorkspace':
        url = `${process.env.REACT_APP_API_BASE_URL}/admin/noworkspace/SCRnML44eTrx9Izk`;
        break;
      case 'oktaProviderNotInAxonWorkspace':
        url = `${process.env.REACT_APP_API_BASE_URL}/admin/oktaProviderNotInWorkspace/SCRnML44eTrx9Izk`;
        break;
      case 'orphanedNotifications':
        url = `${process.env.REACT_APP_API_BASE_URL}/admin/orphanedNotifications`
        break;
      case 'orphanedChannels':
        url = `${process.env.REACT_APP_API_BASE_URL}/admin/orphanedChannels`
        break;
      // Add more cases as needed
      default:
        break;
    }

    // Retrieve the pxtoken from localStorage
    const pxtoken = localStorage.getItem("pxtoken");

    // Set up the headers
    const headers = {
      'pxtoken': pxtoken,
      // Include any other headers you need
    };

    // Making the Axios request with the headers
    const response = await axios.get(url, { headers });
    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error; // Re-throw the error to be handled by the caller
  }
};

export { fetchData };
