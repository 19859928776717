import React from "react";
import { useEffect, useState } from "react";
import { BarChart, Bar, Tooltip, XAxis, YAxis } from "recharts";
import { fetchJson } from "../authProvider";
import moment from "moment/moment";
import {
  Box,
  Button,
  Container,
  Stack,
  TextField,
  Typography,
  Card,
} from "@mui/material";

export default function App() {
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [items, setItems] = useState([]);
  const data = [];
  // Note: the empty deps array [] means
  // this useEffect will run once
  // similar to componentDidMount()
  useEffect(() => {
    fetchJson("https://api.carbonvoice.app/admin/message/statistics").then(
      (result) => {
        console.log("got result?");
        for (var instance of result.json) {
          var row = {
            date: moment(instance.date).format("MMM D"),
            canceled: instance.messages["canceled"] ?? 0,
            active: instance.messages["active"] ?? 0,
            inprogress: instance.messages["inprogress"] ?? 0,
            processing: instance.messages["processing"] ?? 0,
            initializing: instance.messages["initializing"] ?? 0,
          };

          row.bad = row.inprogress + row.processing + row.initializing;
          if (row.active > 0 && row.bad > 0) {
            row.badPercent = row.bad / row.active;
          } else {
            row.badPercent = 0;
          }

          data.push(row);
        }
        setItems(data);
      },
      // Note: it's important to handle errors here
      // instead of a catch() block so that we don't swallow
      // exceptions from actual bugs in components.
      (error) => {
        setIsLoaded(true);
        setError(error);
      }
    );
  }, []);
  return (
    <Box>
      <h3>Messages Per Day</h3>
      <Card>
        <BarChart
          width={1600}
          height={300}
          margin={{ top: 50, right: 30, left: 20, bottom: 5 }}
          data={items}
          syncId={"anyId"}
        >
          <Tooltip />
          <XAxis dataKey="date" />
          <YAxis yAxisId="left" />
          <Bar
            dataKey="inprogress"
            fill="#AA0000"
            yAxisId="left"
            stackId={"stack"}
          />
          <Bar
            dataKey="processing"
            fill="#CC0000"
            yAxisId="left"
            stackId={"stack"}
          />
          <Bar
            dataKey="initializing"
            fill="#AAAA00"
            yAxisId="left"
            stackId={"stack"}
          />
          <Bar
            dataKey="active"
            fill="#00AA00"
            yAxisId="left"
            stackId={"stack"}
          />
        </BarChart>
          </Card>
          <h3>Bad Message %</h3>
      <Card>
        
        <BarChart
          width={1600}
          height={300}
          margin={{ top: 50, right: 30, left: 20, bottom: 5 }}
          data={items}
          syncId={"anyId"}
        >
          <Tooltip />
          <XAxis dataKey="date" />
          <YAxis yAxisId="left" />
          <Bar dataKey="badPercent" fill="#0000AA" yAxisId="left" />
        </BarChart>
      </Card>
    </Box>
  );
}
