import React from "react";
import { Button } from "@mui/material";
import { useMutation } from "react-query";
import { useDataProvider } from "react-admin";

const RecreateMP3Button = ({ messageId }) => {
  const dataProvider = useDataProvider();

  const { mutate, isLoading } = useMutation(["recreateMp3", messageId], () =>
    dataProvider.recreateMP3({ id: messageId })
  );

  return (
    <Button onClick={() => mutate()} disabled={isLoading}>
      Recreate MP3
    </Button>
  );
};

export default RecreateMP3Button;
