import React from "react";
import { Button } from "@mui/material";
import { useMutation } from "react-query";
import { useDataProvider } from "react-admin";

const RecreateWaveformButton = ({ messageId }) => {
  const dataProvider = useDataProvider();

  const { mutate, isLoading } = useMutation(
    ["recreateWaveform", messageId],
    () => dataProvider.recreateWaveform({ id: messageId })
  );

  return (
    <Button onClick={() => mutate()} disabled={isLoading}>
      Recreate Waveform
    </Button>
  );
};

export default RecreateWaveformButton;
