import * as React from "react";
import {
  List,
  Datagrid,
  TextField,
  DateField,
  ReferenceField,
  useRecordContext,
  BooleanField,
  Link,
  Edit,
  TextInput,
  FormTab,
  TabbedForm,
  Labeled,
  ArrayInput,
  SimpleFormIterator,
  DateInput,
  SingleFieldList,
  ArrayField,
  SelectInput

} from "react-admin";

import { AvatarField as UserAvatarField } from "./users";

import { WorkspaceLinkField } from "./workspaces";
import { Avatar, Box, Typography } from "@mui/material";
import { UserLinkField } from "./users";

// In Ra -> Define filters that you randomly feed to List component filters prop

const channelFilters = [
<TextInput label="Conversation ID" source="_id" alwaysOn />
];

const AvatarField = ({ size = "25", sx }) => {
  const record = useRecordContext();
  if (!record) return null;
  return (
    <Avatar
      src={`${record.image_url}?size=${size}x${size}`}
      style={{ width: parseInt(size, 10), height: parseInt(size, 10) }}
      variant="rounded"
      sx={sx}
    />
  );
};

export const FullNameField = (props) => {
  const { size } = props;
  const record = useRecordContext();
  return record ? (
    <Typography
      variant="body2"
      display="flex"
      flexWrap="nowrap"
      alignItems="center"
      component="div"
      sx={props.sx}
    >
      <AvatarField
        record={record}
        size={size}
        sx={{
          mr: 1,
          mt: -0.5,
          mb: -0.5,
        }}
      />
      {record.name}
    </Typography>
  ) : null;
};

export const ChannelLinkField = (props) => {
  const record = useRecordContext();
  if (!record) {
    return null;
  }
  return (
    <Link to={`/channels/${record.id}`}>
      <FullNameField />
    </Link>
  );
};

// Call from Users to link back to channels.
export const ChannelLinkIdField = (props) => {
  const record = useRecordContext();
  return (
    <Link to={`/channels/${record.channel_id}`}>
      <TextField label={props.label} source="channel_id" />
    </Link>
  );

};

export const ChannelList = (props) => (
  <List {...props} filters={channelFilters}>
    <Datagrid rowClick="edit">
      <AvatarField />
      <TextField source="name" />
      <TextField source="description" />
      <TextField source="dm_hash" />
      <ReferenceField source="workspace_id" reference="workspaces">
        <WorkspaceLinkField />
      </ReferenceField>
      <ReferenceField source="owner_id" reference="users">
        <UserLinkField />
      </ReferenceField>
      <ReferenceField source="created_by" reference="users">
        <UserLinkField />
      </ReferenceField>
      <TextField source="visibility" />
      <ArrayField source="users">
        <Box p={1}>
          <SingleFieldList>
            <ReferenceField source="user_id" reference="users">
              <Box m={0.25}>
                <UserAvatarField size={16} />
              </Box>
            </ReferenceField>
          </SingleFieldList>
        </Box>
      </ArrayField>
      <DateField source="created_at" showTime />
      <DateField source="deleted_on" />
    </Datagrid>
  </List>
);

const ChannelTitle = () => {
  const record = useRecordContext();
  return record ? (
    <span>
      {record.name} ({record.id})
    </span>
  ) : null;
};

export const ChannelEdit = () => (
  <Edit title={<ChannelTitle />}>
    <TabbedForm>
      <FormTab label="Info">
        <Labeled label="ID">
          <TextField source="id" />
        </Labeled>
        <Labeled label="DM Hash">
          <TextField source="dm_hash" />
        </Labeled>
        <Labeled label="Is Private?">
          <BooleanField source="is_private" />
        </Labeled>
        <TextInput source="name" />
        <SelectInput 
          source="visibility"
          choices={[
            { id: "private", name: "Private" },
            { id: "workspace", name: "Workspace" },
            { id: "public", name: "Public" },
          ]}
        />
      </FormTab>

      <FormTab label="Users">
        <ArrayInput source="users" label="">
          <SimpleFormIterator>
            <ReferenceField source="user_id" reference="users">
              <Labeled source="user">
                <UserLinkField />
              </Labeled>
            </ReferenceField>
            <TextField source="role" />
            <DateInput source="joined_at" />
          </SimpleFormIterator>
        </ArrayInput>
      </FormTab>
    </TabbedForm>
  </Edit>
);
