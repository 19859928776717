import { fetchUtils } from "react-admin";

export const fetchJson = (url, options = {}) => {
  if (!options.headers) {
    options.headers = new Headers({ Accept: "application/json" });
  }
  // add your own headers here
  options.headers.set("PXToken", localStorage.getItem("pxtoken"));
  return fetchUtils.fetchJson(url, options);
};

const authProvider = {
  // called when the user attempts to log in
  login: ({ username }) => {
    localStorage.setItem("pxtoken", username);
    // accept all username/password combinations
    return Promise.resolve();
  },
  // called when the user clicks on the logout button
  logout: () => {
    localStorage.removeItem("pxtoken");
    return Promise.resolve();
  },
  // called when the API returns an error
  checkError: ({ status }) => {
    if (status === 401 || status === 403) {
      localStorage.removeItem("pxtoken");
      return Promise.reject();
    }
    return Promise.resolve();
  },
  // called when the user navigates to a new location, to check for authentication
  checkAuth: () => {
    return localStorage.getItem("pxtoken")
      ? Promise.resolve()
      : Promise.reject();
  },
  getIdentity: async () => {
    const {
      json: {
        user: { user_guid, first_name, last_name, image_url, role },
      },
    } = await fetchJson(`${process.env.REACT_APP_API_BASE_URL + process.env.REACT_APP_WHOAMI_PATH}`);

    localStorage.setItem("role", role);

    return {
      id: user_guid,
      fullName: `${first_name} ${last_name}`,
      avatar: image_url,
    };
  },
  getPermissions: async () => {
    return localStorage.getItem("role");
  },
};

export default authProvider;
