import React, { useRef, useState } from "react";

import { Stack, TextField } from "@mui/material";

const InputCode = ({ length, label, loading, onComplete }) => {
  const [code, setCode] = useState([...Array(length)].map(() => ""));
  const inputs = useRef([]);

  const processInput = (e, slot) => {
    const num = e.target.value;
    if (/[^0-9]/.test(num)) return;
    const newCode = [...code];
    newCode[slot] = num;
    setCode(newCode);
    if (slot !== length - 1) {
      inputs.current[slot + 1]?.focus();
    }
    if (newCode.every((num) => num !== "")) {
      onComplete(newCode.join(""));
    }
  };

  const processPaste = (e, slot) => {
    // todo
  };

  const onKeyUp = (e, slot) => {
    if (e.keyCode === 8 && !code[slot] && slot !== 0) {
      const newCode = [...code];
      newCode[slot - 1] = "";
      setCode(newCode);
      inputs.current[slot - 1]?.focus();
    }
  };

  return (
    <div className="code-input">
      <label className="code-label">{label}</label>
      <Stack direction="row" spacing={1}>
        {code.map((num, idx) => {
          return (
            <TextField
              key={idx}
              type="text"
              inputMode="numeric"
              value={num}
              disabled={loading}
              autoFocus={!code[0].length && idx === 0}
              onChange={(e) => processInput(e, idx)}
              onPaste={(e) => processPaste(e, idx)}
              onKeyUp={(e) => onKeyUp(e, idx)}
              inputRef={(ref) => inputs.current.push(ref)}
              inputProps={{
                maxLength: 1,
                style: { textAlign: "center" },
              }}
            />
          );
        })}
      </Stack>
    </div>
  );
};

export default InputCode;
