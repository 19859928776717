import * as React from "react";
import { Admin, Resource } from "react-admin";
import { UserEdit, UserList } from "./resources/users";

import UserIcon from "@mui/icons-material/Group";
import SpatialAudioOffIcon from "@mui/icons-material/SpatialAudioOff";
import ForumIcon from "@mui/icons-material/Forum";
import WorkspacesIcon from "@mui/icons-material/Workspaces";
import QueryStatsIcon from '@mui/icons-material/QueryStats';

import authProvider, { fetchJson } from "./authProvider";
import { lightTheme } from "./layout/theme";

import "./App.css";
import Dashboard from "./dashboard/Dashboard";
import dataProvider from "./dataProvider";
import utilitiesDataProvider from './utilitiesDataProvider';
import { WorkspaceList, WorkspaceEdit } from "./resources/workspaces";
import { MessageList } from "./resources/messages";
import { MessageStatList } from "./resources/message-statistics";
import { ChannelEdit, ChannelList } from "./resources/channels";
import UtilityList from "./resources/utilities";
import Login from "./Login";

const App = () => (
  <Admin
    dataProvider={dataProvider(
      `${process.env.REACT_APP_API_BASE_URL + process.env.REACT_APP_DATA_PROVIDER_BASE}`,
      fetchJson
    )}
    loginPage={Login}
    authProvider={authProvider}
    theme={lightTheme}
    dashboard={Dashboard}
  >
    {(permissions) => (
      <>
        {permissions === "admin" ? (
          <>
            <Resource
              name="messages"
              list={MessageList}
              icon={SpatialAudioOffIcon}
            />
            <Resource
              name="users"
              list={UserList}
              edit={UserEdit}
              recordRepresentation="name"
              icon={UserIcon}
            />
            <Resource
              name="workspaces"
              list={WorkspaceList}
              edit={WorkspaceEdit}
              icon={WorkspacesIcon}
            />
            <Resource
              name="channels"
              options={{
                label: "Conversations",
              }}
              list={ChannelList}
              edit={ChannelEdit}
              icon={ForumIcon}
            />
            {/* Utilities section with custom queries */}
            <Resource
              name="utilities"
              options={{
                label: 'Utilities',
              }}
              list={UtilityList}
              icon={QueryStatsIcon}
              //dataProvider={utilitiesDataProvider(fetchJson)} // Use the custom data provider
            />
            <Resource
              name="message-stats"
              options={{
                label: "Message Statistics",
              }}
              list={MessageStatList}              
              icon={SpatialAudioOffIcon}
            />
          </>
        ) : null}
      </>
    )}
  </Admin>
);

export default App;
