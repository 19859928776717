import "firebase/auth";
import { initializeApp } from "firebase/app";
import {
  getAuth,
  GoogleAuthProvider,
  OAuthProvider,
  signInWithPopup,
} from "firebase/auth";
import phone from "phone";
import { useCallback, useMemo, useState } from "react";

import { LoadingButton } from "@mui/lab";
import axios from "axios";
import {
  Box,
  Button,
  Container,
  Stack,
  TextField,
  Typography,
} from "@mui/material";

import Apple from "./components/icons/Apple";
import Google from "./components/icons/Google";
import InputCode from "./components/InputCode";
import { useLogin, useNotify } from "react-admin";

const firebaseConfig = {
  apiKey: "AIzaSyApBHm43B4ujs2lEw7fEnzXVkUdXU2vCU0",
  authDomain: "phononx-855e0.firebaseapp.com",
  projectId: "phononx-855e0",
  storageBucket: "phononx-855e0.appspot.com",
  messagingSenderId: "891663630331",
  appId: "1:891663630331:web:c4d1391752309ec483d55e",
  measurementId: "G-4XP2Y74C6X",
};

initializeApp(firebaseConfig);

const Login = () => {
  const login = useLogin();
  const notify = useNotify();

  const onLoginComplete = useCallback(
    async (pxtoken) => {
      login({ username: pxtoken, password: pxtoken });
    },
    [login]
  );

  const [key, setKey] = useState(null);
  const [value, setValue] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const isPhone = useMemo(() => phone(value).isValid, [value]);

  const isEmail = useMemo(
    () =>
      value
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        ),
    [value]
  );

  const onCodeComplete = async (code) => {
    if (loading) return;

    setLoading(true);

    try {
      const {
        data: { success, errmsg, pxtoken },
      } = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/magiclink/code/check?magickey=${key}&code=${code}`
      );

      if (!success) {
        throw new Error(errmsg);
      }

      onLoginComplete(pxtoken);
    } catch (e) {
      notify(e);
    }
    setLoading(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);

    if (!isPhone && !isEmail) {
      setError(
        new Error("Please enter a valid phone number or email address.")
      );
      return;
    }

    try {
      setLoading(true);
      const {
        data: { success, magicKey, errmsg },
      } = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/magiclink`, {
        method: isPhone ? "phone" : "email",
        value,
      });

      if (!success) {
        throw new Error(errmsg);
      }

      setLoading(false);
      setKey(magicKey);
    } catch (e) {
      console.error(e);
      setLoading(false);
      notify(e.message);
    }
  };

  const signInWithApple = async () => {
    try {
      const provider = new OAuthProvider("apple.com");
      provider.addScope("email");
      provider.addScope("name");
      const auth = getAuth();
      const result = await signInWithPopup(auth, provider);

      const user = result.user;

      const refreshToken = user.refreshToken;

      const {
        data: { success, errmsg, pxtoken },
      } = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/user/verify`,
        {
          refreshToken,
          accessToken: null,
          idToken: user.accessToken,
          formerge: false,
        }
      );

      if (!success) {
        throw new Error(errmsg);
      }

      onLoginComplete(pxtoken);
    } catch (e) {
      console.error(e);
      setError(e);
    }
  };

  const signInWithGoogle = async () => {
    try {
      const provider = new GoogleAuthProvider();
      const auth = getAuth();
      const result = await signInWithPopup(auth, provider);
      const user = result.user;

      const refreshToken = user.refreshToken;

      const {
        data: { success, errmsg, pxtoken },
      } = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/user/verify`,
        {
          refreshToken,
          accessToken: null,
          idToken: user.accessToken,
          formerge: false,
        }
      );

      if (!success) {
        throw new Error(errmsg);
      }

      onLoginComplete(pxtoken);
    } catch (e) {
      console.error(e);
      setError(e);
    }
  };

  return (
    <Box
      display="flex"
      flexGrow={1}
      justifyContent="center"
      alignItems="center"
      mt={10}
    >
      <Container maxWidth="xs">
        <Typography variant="h6">Login</Typography>
        {key ? (
          <>
            <Box
              display="flex"
              alignItems="center"
              flexDirection="column"
              mt={2}
            >
              <InputCode
                label="Enter the 5-digit code"
                length={5}
                loading={loading}
                onComplete={onCodeComplete}
              />
              {error && (
                <Typography color="error" variant="caption" mt={2}>
                  {error?.message}
                </Typography>
              )}
            </Box>
          </>
        ) : (
          <>
            <Box
              display="flex"
              alignItems="center"
              flexDirection="column"
              mt={2}
            >
              <form onSubmit={handleSubmit} style={{ width: "100%" }}>
                <TextField
                  value={value}
                  onChange={(e) => setValue(e.target.value)}
                  variant="filled"
                  label="Email or phone number"
                  error={!!error}
                  fullWidth
                />
                {error && (
                  <Typography color="error" variant="caption" mt={2}>
                    {error?.message}
                  </Typography>
                )}
                <LoadingButton
                  variant="contained"
                  sx={{ my: 2 }}
                  fullWidth
                  type="submit"
                  loading={loading}
                >
                  Next
                </LoadingButton>
              </form>

              <Box display="flex" alignItems="center" width="100%">
                <hr style={{ flexGrow: 1 }} />
                <Typography variant="caption" px={2}>
                  or
                </Typography>
                <hr style={{ flexGrow: 1 }} />
              </Box>

              <Stack spacing={2} mt={2} width="100%">
                <Button
                  variant="outlined"
                  startIcon={<Google />}
                  onClick={signInWithGoogle}
                >
                  Sign in with Google
                </Button>
                <Button
                  variant="outlined"
                  startIcon={<Apple />}
                  onClick={signInWithApple}
                >
                  Sign in with Apple
                </Button>
              </Stack>
            </Box>
          </>
        )}
      </Container>
    </Box>
  );
};

export default Login;
