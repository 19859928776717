import { SvgIcon } from '@mui/material';

const Apple = (props) => {
  return (
    <SvgIcon {...props}>
      <path
        d="M15.8694 0.857143C12.9796 1.53479 11.8776 3.39832 11.8286 5.86689C12.9306 5.77008 13.5551 5.64908 14.4367 4.76571C15.4776 3.72504 15.9429 2.38185 15.9429 1.37748C15.9429 1.12336 15.9184 1.01445 15.8694 0.857143ZM16.0776 5.50387C15.502 5.50387 14.7918 5.63698 13.9469 5.9274C13.151 6.20572 12.5633 6.35093 12.1959 6.35093C11.902 6.35093 11.3388 6.21782 10.4939 5.9758C9.63674 5.73378 8.90204 5.60067 8.31429 5.60067C5.49796 5.60067 3.42857 8.25076 3.42857 12.0141C3.42857 13.9987 4.02857 16.08 5.24082 18.2218C6.45306 20.3637 7.70204 21.4286 8.93878 21.4286C9.3551 21.4286 9.90612 21.2955 10.5673 21.005C11.2163 20.7146 11.8041 20.6057 12.3184 20.6057C12.8449 20.6057 13.4694 20.7388 14.1673 20.9929C14.9265 21.2713 15.4898 21.4286 15.8939 21.4286C18 21.4286 19.9347 17.9435 20.5714 15.8743C19.102 15.4387 17.7796 13.5993 17.7796 11.5664C17.7796 9.69076 18.6857 8.65008 19.9837 7.54891C19.1265 6.49614 18.0367 5.50387 16.0776 5.50387Z"
        fill="#1D1C1D"
      />
    </SvgIcon>
  );
};

export default Apple;
