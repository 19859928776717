import React, { useState, useEffect } from 'react';
import { CircularProgress, Table, TableBody, TableCell, TableHead, TableRow, Typography, Button } from '@mui/material';
import utilitiesDataProvider from '../utilitiesDataProvider'; // Import your data provider
import { fetchData } from './UtilityFetch';

const UtilityList = ({ selectedItem, setData, data }) => {
  const [loading, setLoading] = useState(false);  // Initially set loading to false
  const [error, setError] = useState(null);

  useEffect(() => {
    const loadData = async () => {
      if (!selectedItem) {
        setData([]);
        return;
      }

      setLoading(true);
      try {
        let result = await fetchData(selectedItem); // Use the fetchData function
  
        let transformedData;
        if (selectedItem === 'axonUsersNotInWorkspace' || selectedItem === 'oktaProviderNotInAxonWorkspace') {
          transformedData = result.map(item => item.user).filter(user => user !== null);
        } else if (selectedItem === 'orphanedChannels' || selectedItem === 'orphanedNotifications') {
          // Transform data for orphanedChannels if needed
          transformedData = result;
        }
        // ... other cases ...
  
        setData(transformedData);
      } catch (error) {
        console.error('Error fetching data:', error);
        setError('Error fetching data');
      } finally {
        setLoading(false);
      }
    };
  
    loadData();
  }, [selectedItem]);

  if (loading) {
    return <CircularProgress />;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  // Render content only if selectedItem is not null
  if (!selectedItem) {
    return <Typography>Please Choose a query to run</Typography>;
  }

// Conditional rendering based on the selected item
if (selectedItem === 'orphanedChannels') {
  return (
    <div>
      {/* Render orphanedChannels data */}
      {/* Example: */}
      <Typography variant="h4" style={{ margin: '20px 0' }}>
        {selectedItem.replace(/([A-Z])/g, ' $1').trim()}
      </Typography>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>ID</TableCell>
            <TableCell>Name</TableCell>
            <TableCell>Created By</TableCell>
            <TableCell>Total Messages</TableCell>
            <TableCell>Workspace ID</TableCell>
            {/* Add other headers */}
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((channel, index) => (
            <TableRow key={index}>
              <TableCell>{channel._id}</TableCell>
              <TableCell>{channel.name || 'N/A'}</TableCell>
              <TableCell>{channel.created_by || 'N/A'}</TableCell>
              <TableCell>{channel.total_messages || 'N/A'}</TableCell>
              <TableCell>{channel.workspace_id || 'N/A'}</TableCell>
              {/* Render other data fields */}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
}

  return (
    <div style={{ marginLeft: '20px', width: '100%' }}>
      <Typography variant="h4" style={{ margin: '20px 0' }}>
        {selectedItem.replace(/([A-Z])/g, ' $1').trim()}
      </Typography>

      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Id</TableCell>
            <TableCell>First Name</TableCell>
            <TableCell>Last Name</TableCell>
            <TableCell>Email</TableCell>
            <TableCell>Plan Type</TableCell>

          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((user, index) => {
            if (!user) return null; // Skip rendering if user is null

            return (
              <TableRow key={index}>
                <TableCell>{user._id}</TableCell>
                <TableCell>{user.first_name}</TableCell>
                <TableCell>{user.last_name}</TableCell>
                <TableCell>{user.email_txt}</TableCell>
                <TableCell>{user.plan_type}</TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </div>
  );
};

export default UtilityList;