import * as React from 'react';
import { Card, CardContent, Button, styled } from '@mui/material';
import UtilityList from '../components/UtilityList'; // Adjust the path as necessary


const StyledLink = styled('div')(({ theme }) => ({
  cursor: 'pointer',
  color: theme.palette.text.primary,
  '&:hover': {
    color: theme.palette.primary.main,
  },
}));


const Sidebar = ({ onSelect }) => (
  <Card sx={{ order: -1, flex: '0 0 20em', mr: 2, mt: 8, alignSelf: 'flex-start', fontSize: '0.8em' }}>
    <CardContent sx={{ pt: 1,   cursor: 'pointer',
  color: 'text.primary',
  '&:hover': {
    color: 'primary.main', // Change color on hover
  }, }} >
      <StyledLink onClick={() => onSelect('oktaProviderNotInAxonWorkspace')}>
        Okta provider not in Axon workspace
      </StyledLink>
      <StyledLink onClick={() => onSelect('axonUsersNotInWorkspace')}>
        Axon Users not in workspace
      </StyledLink>
      <StyledLink onClick={() => onSelect('orphanedNotifications')}>
        Orphaned Notifications
      </StyledLink>
      <StyledLink onClick={() => onSelect('orphanedChannels')}>
        Orphaned Channels
      </StyledLink>
    </CardContent>
  </Card>
);

// Function to convert data to CSV
const convertToCSV = (data) => {
  if (!data || data.length === 0) return '';

  const array = [Object.keys(data[0])].concat(data.map(item => Object.values(item)));
  return array.map(it => it.join(",")).join("\n");
};

const Utilities = () => {
  const [selectedItem, setSelectedItem] = React.useState(null);
  const [data, setData] = React.useState([]); // State to hold the current dataset

  // Function to handle the download of CSV
  const handleDownloadCSV = () => {
    const csvData = convertToCSV(data);
    if (!csvData) {
      alert("No data available to download.");
      return;
    }

    const encodedUri = encodeURI("data:text/csv;charset=utf-8," + csvData);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "data.csv");
    document.body.appendChild(link);
    link.click();
  };

  return (
    <div style={{ display: "flex" }}>
      <Sidebar onSelect={setSelectedItem} />
      <div>
        <UtilityList selectedItem={selectedItem} setData={setData} data={data} />
        <Button variant="contained" color="primary" onClick={handleDownloadCSV}>
          Download CSV
        </Button>
      </div>
    </div>
  );
};

export default Utilities;
