import { SvgIcon } from '@mui/material';

const Google = (props) => {
  return (
    <SvgIcon {...props}>
      <path
        d="M12 6.5625C13.3275 6.5625 14.5162 7.02 15.4537 7.9125L18.0225 5.34375C16.4625 3.8925 14.4262 3 12 3C8.4825 3 5.44125 5.0175 3.96 7.9575L6.9525 10.2787C7.66125 8.145 9.6525 6.5625 12 6.5625Z"
        fill="#EA4335"
      />
      <path
        d="M20.6175 12.2062C20.6175 11.6175 20.5613 11.0475 20.475 10.5H12V13.8825H16.8525C16.635 14.9925 16.005 15.9375 15.06 16.575L17.9588 18.825C19.65 17.2575 20.6175 14.94 20.6175 12.2062V12.2062Z"
        fill="#4285F4"
      />
      <path
        d="M6.94875 13.7213C6.76875 13.1775 6.66375 12.6 6.66375 12C6.66375 11.4 6.765 10.8225 6.94875 10.2788L3.95625 7.9575C3.345 9.1725 3 10.545 3 12C3 13.455 3.345 14.8275 3.96 16.0425L6.94875 13.7213Z"
        fill="#FBBC05"
      />
      <path
        d="M12 21C14.43 21 16.4738 20.2012 17.9588 18.8212L15.06 16.5712C14.2538 17.115 13.215 17.4338 12 17.4338C9.6525 17.4338 7.66125 15.8512 6.94875 13.7175L3.95625 16.0387C5.44125 18.9825 8.4825 21 12 21Z"
        fill="#34A853"
      />
    </SvgIcon>
  );
};

export default Google;
