import Hls from "hls.js";
import { useEffect, useRef } from "react";

function Player({ message }) {
  const audioRef = useRef();

  const id = message.message_guid ?? message._id;

  useEffect(() => {
    var baseUrl = `${process.env.REACT_APP_API_BASE_URL + process.env.REACT_APP_STREAM_PATH}/${id}`;

    var audioSrc = `${baseUrl}/stream.m3u8?pxtoken=${localStorage.getItem(
      "pxtoken"
    )}`;

    if (Hls.isSupported()) {
      var hls = new Hls({
        xhrSetup: function (xhr, url) {
          // if need to replace URL
          var s3BaseUrl = `https://pxmessages.s3.us-east-2.amazonaws.com/stream/${id}`;
          if (url.startsWith(s3BaseUrl)) {
            url = url.replace(s3BaseUrl, baseUrl);
            xhr.open("GET", url, true);
            xhr.setRequestHeader("pxtoken", localStorage.getItem("pxtoken"));
          }
        },
      });
      hls.loadSource(audioSrc);
      hls.attachMedia(audioRef.current);

    } else if (audioRef.current.canPlayType("application/vnd.apple.mpegurl")) {
      audioRef.current.src = audioSrc;
    }

    return () => {
      hls && hls.destroy();
    };
  }, [id, audioRef]);

  return (
    <>
      <audio ref={audioRef} controls style={{ width: "100%" }} />
    </>
  );
}

export default Player;
